<template>
  <div>
    <a-form ref="formRef" :model="formState" layout="vertical" name="basic" autocomplete="off" :rules="rules">
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item label="方案名称" name="planRuleName">
            <a-input :maxlength="20" v-model:value="formState.planRuleName" />
          </a-form-item>
        </a-col>
        <a-col :span="11">
          <a-form-item label="适用部门" name="adaptDepartments">
            <a-select :disabled="isDisabled" :filter-option="filterOption" mode="multiple" show-search ref="select" v-model:value="currDepartments">
              <a-select-option v-for="(item, index) in departments" :value="item.id" :name="item.name">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item label="管理人" name="responsibleEmployeeId">
            <a-select :filter-option="filterSouOption" show-search ref="select" @change="employeesChange" v-model:value="formState.responsibleEmployeeId">
              <a-select-option v-for="(item, index) in employees" :value="item.id" :name="item.name">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <!-- 个人提成 -->
      <h3 class="m-bottom">个人提成</h3>
      <div class="m-bottom">提成方式</div>
      <a-radio-group v-model:value="formState.personalType" name="radioGroup" class="m-bottom">
        <a-radio value="1">
          <span>固定比例</span>
          <a-popover title="">
            <template #content>
              <p>根据当月付款计算提成比例</p>
            </template>
            <QuestionCircleOutlined style="color: #999999; margin-left: 4px;" />
          </a-popover>
        </a-radio>
        <a-radio value="2">
          <span>阶梯比例</span>
          <a-popover title="">
            <template #content>
              <p>根据当月签单量计算当月签单付款时的提成比例</p>
            </template>
            <QuestionCircleOutlined style="color: #999999; margin-left: 4px;" />
          </a-popover>
        </a-radio>
      </a-radio-group>
      <div class="m-bottom-large" v-if="formState.personalType === '1'">
        <p class="m-bottom">提成比例</p>
        <div class="m-bottom">
          <a-input type="text" v-model:value="formState.personalFixPercent" style="width: 50px; margin-right: 8px" />
          <span>%</span>
        </div>
      </div>
      <div class="m-bottom-large" v-if="formState.personalType === '2'">
        <div v-for="(item, index) in formState.personalRuleContent">
          <p class="m-bottom">
            <span style="font-weight: bold;">阶梯条件{{ index + 1 }} </span>
            <DeleteOutlined v-if="index > 0 && index < formState.personalRuleContent.length - 1"
              @click="personalDelete(index)"
              style="color: #1677ff; font-size: 16px; margin-left: 6px; cursor: pointer;" />
          </p>
          <div class="m-bottom" v-if="index === 0">
            <span>签单数 小于 </span>
            <a-input type="text" v-model:value="item.max" style="width: 40px" />
            <span> 单</span>
          </div>
          <div class="m-bottom" v-else-if="index === formState.personalRuleContent.length - 1">
            <span>签单数 大于 </span>
            <a-input type="text" v-model:value="item.min" style="width: 40px" />
            <span> 单</span>
          </div>
          <div class="m-bottom" v-else>
            <span>签单数 介于(包含) </span>
            <a-input type="text" v-model:value="item.min" style="width: 40px" />
            <span> - </span>
            <a-input type="text" v-model:value="item.max" style="width: 40px" />
            <span> 单</span>
          </div>
          <p class="m-bottom">提成比例</p>
          <div class="m-bottom">
            <a-input type="text" v-model:value="item.percent" style="width: 50px; margin-right: 8px" />
            <span>%</span>
          </div>
        </div>
        <a-button @click="personalAdd" class="m-top-alrge">+ 新增条件</a-button>
      </div>

      <!-- 部门经理提成 -->
      <h3 class="m-bottom">部门经理提成</h3>
      <div class="m-bottom">提成方式</div>
      <a-radio-group v-model:value="formState.departmentType" name="radioGroup" class="m-bottom">
        <a-radio value="0">无提成</a-radio>
        <a-radio value="1">
          <span>固定比例</span>
          <a-popover title="">
            <template #content>
              <p>按团队的总付款计算提成比例</p>
            </template>
            <QuestionCircleOutlined style="color: #999999; margin-left: 4px;" />
          </a-popover>
        </a-radio>
        <a-radio value="2">
          <span>阶梯比例</span>
          <a-popover title="">
            <template #content>
              <p>按团队的总签单量计算当月签单付款时的提成比例</p>
            </template>
            <QuestionCircleOutlined style="color: #999999; margin-left: 4px;" />
          </a-popover>
        </a-radio>
      </a-radio-group>
      <div class="m-bottom-large" v-if="formState.departmentType === '1'">
        <p class="m-bottom">提成比例</p>
        <div class="m-bottom">
          <a-input type="text" v-model:value="formState.departmentFixPercent" style="width: 50px; margin-right: 8px" />
          <span>%</span>
        </div>
      </div>
      <div class="m-bottom-large" v-if="formState.departmentType === '2'">
        <div v-for="(item, index) in formState.departmentRuleContent">
          <p class="m-bottom">
            <span style="font-weight: bold;">阶梯条件{{ index + 1 }} </span>
            <DeleteOutlined v-if="index > 0 && index < formState.departmentRuleContent.length - 1"
              @click="manageDelete(index)" style="color: #1677ff; font-size: 16px; margin-left: 6px; cursor: pointer;" />
          </p>
          <div class="m-bottom" v-if="index === 0">
            <span>签单数 小于 </span>
            <a-input type="text" v-model:value="item.max" style="width: 40px" />
            <span> 单</span>
          </div>
          <div class="m-bottom" v-else-if="index === formState.departmentRuleContent.length - 1">
            <span>签单数 大于 </span>
            <a-input type="text" v-model:value="item.min" style="width: 40px" />
            <span> 单</span>
          </div>
          <div class="m-bottom" v-else>
            <span>签单数 介于(包含) </span>
            <a-input type="text" v-model:value="item.min" style="width: 40px" />
            <span> - </span>
            <a-input type="text" v-model:value="item.max" style="width: 40px" />
            <span> 单</span>
          </div>
          <p class="m-bottom">提成比例</p>
          <div class="m-bottom">
            <a-input type="text" v-model:value="item.percent" style="width: 50px; margin-right: 8px" />
            <span>%</span>
          </div>
        </div>
        <a-button @click="manageAdd" class="m-top-alrge">+ 新增条件</a-button>
      </div>
      <div v-if="this.formState.departmentType != '0'">
        <div class="m-bottom">
          <span>部门经理订单是否叠加提成</span>
          <a-popover title="">
            <template #content>
              <p>若经理属于本部门，经理自己签单按照个人提成后，是否还要按照部门经理规则再提成</p>
            </template>
            <QuestionCircleOutlined style="color: #999999; margin-left: 4px;" />
          </a-popover>
        </div>
        <a-radio-group v-model:value="formState.departmentDouble" name="radioGroup" class="m-bottom-large">
          <a-radio value="0">否</a-radio>
          <a-radio value="1">是</a-radio>
        </a-radio-group>
      </div>

      <!-- 上一级经理提成 -->
      <h3 class="m-top-alrge">
        <span>上一级经理提成</span>
        <a-popover title="">
          <template #content>
            <p>决定本部门的上级部门经理是否提成</p>
          </template>
          <QuestionCircleOutlined style="color: #999999; margin-left: 4px;" />
        </a-popover>
      </h3>
      <div class="m-bottom">提成方式</div>
      <a-radio-group v-model:value="formState.lastType" name="radioGroup" class="m-bottom">
        <a-radio value="0">无提成</a-radio>
        <a-radio value="1">
          <span>固定比例</span>
          <a-popover title="">
            <template #content>
              <p>按团队的总付款计算提成比例</p>
            </template>
            <QuestionCircleOutlined style="color: #999999; margin-left: 4px;" />
          </a-popover>
        </a-radio>
        <a-radio value="2">
          <span>阶梯比例</span>
          <a-popover title="">
            <template #content>
              <p>按团队的总签单量计算当月签单付款时的提成比例</p>
            </template>
            <QuestionCircleOutlined style="color: #999999; margin-left: 4px;" />
          </a-popover>
        </a-radio>

      </a-radio-group>
      <div class="m-bottom-large" v-if="formState.lastType === '1'">
        <p class="m-bottom">提成比例</p>
        <div class="m-bottom">
          <a-input type="text" v-model:value="formState.lastFixPercent" style="width: 50px; margin-right: 8px" />
          <span>%</span>
        </div>
      </div>
      <div class="m-bottom-large" v-if="formState.lastType === '2'">
        <div v-for="(item, index) in formState.lastRuleContent">
          <p class="m-bottom">
            <span style="font-weight: bold;">阶梯条件{{ index + 1 }} </span>
            <DeleteOutlined v-if="index > 0 && index < formState.lastRuleContent.length - 1"
              @click="preManageDelete(index)"
              style="color: #1677ff; font-size: 16px; margin-left: 6px; cursor: pointer;" />
          </p>
          <div class="m-bottom" v-if="index === 0">
            <span>签单数 小于 </span>
            <a-input type="text" v-model:value="item.max" style="width: 40px" />
            <span> 单</span>
          </div>
          <div class="m-bottom" v-else-if="index === formState.lastRuleContent.length - 1">
            <span>签单数 大于 </span>
            <a-input type="text" v-model:value="item.min" style="width: 40px" />
            <span> 单</span>
          </div>
          <div class="m-bottom" v-else>
            <span>签单数 介于(包含) </span>
            <a-input type="text" v-model:value="item.min" style="width: 40px" />
            <span> - </span>
            <a-input type="text" v-model:value="item.max" style="width: 40px" />
            <span> 单</span>
          </div>
          <p class="m-bottom">提成比例</p>
          <div class="m-bottom">
            <a-input type="text" v-model:value="item.percent" style="width: 50px; margin-right: 8px" />
            <span>%</span>
          </div>
        </div>
        <a-button @click="preManageAdd" class="m-top-alrge">+ 新增条件</a-button>
      </div>

      <a-form-item>
        <a-button type="primary" html-type="submit" @click="submit">确认修改</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
export default {
  name: 'SaasCrmRulesDetail',
  props: ['action', 'type', 'selectedRows', 'disabledValue', 'filterForm'],
  data() {
    return {
      isDisabled: false,
      rules: {
        planRuleName: [
          { required: true, message: '请输入方案名称' }
        ],
        adaptDepartments: [
          { required: true, message: '请选择适用部门' }
        ],
        responsibleEmployeeId: [
          { required: true, message: '请选择管理人' }
        ],
      },
      formState: {
        planRuleName: '',
        adaptDepartments: [],
        responsibleEmployeeId: '',
        responsibleEmployeeName: '',

        personalType: '1',
        personalFixRate: '',
        personalFixPercent: '',
        personalRuleContent: [
          {
            min: null,
            max: '',
            rate: '',
            percent: '',
          },
          {
            min: '',
            max: null,
            rate: '',
            percent: '',
          }
        ],

        departmentType: '0',
        departmentDouble: '0',
        departmentFixRate: '',
        departmentFixPercent: '',
        departmentRuleContent: [
          {
            min: null,
            max: '',
            rate: '',
            percent: '',
          },
          {
            min: '',
            max: null,
            rate: '',
            percent: '',
          }
        ],

        lastType: '0',
        lastFixRate: '',
        lastFixPercent: '',
        lastRuleContent: [
          {
            min: null,
            max: '',
            rate: '',
            percent: '',
          },
          {
            min: '',
            max: null,
            rate: '',
            percent: '',
          }
        ],
      },
      currDepartments: [],
      departments: [],
      employees: []
    };
  },

  mounted() {
    this.getDepartmentsAndEmployees();
    this.isDisabled = this.selectedRows[0].status == 1 ? true : false;
  },

  methods: {
    async getDepartmentsAndEmployees() {
      try {
        let res = await this.$http.post('/institutions/commission/rule/getDepartmentsAndEmployees');
        if (res.code == '200') {
          this.departments = res.departments;
          this.employees = res.employees;
          this.formState = this.selectedRows[0];
          let deArr = this.selectedRows[0].adaptDepartments.split(',');
          deArr.map(item => {
            this.currDepartments.push(Number(item));
          })

          this.formState.personalType = String(this.formState.personalType);
          this.formState.departmentType = String(this.formState.departmentType);
          this.formState.lastType = String(this.formState.lastType);
          this.formState.departmentDouble = String(this.formState.departmentDouble);

          this.formState.personalFixPercent = this.formState.personalFixRate ? this.formState.personalFixRate * 100 : this.formState.personalFixPercent;
          this.formState.departmentFixPercent = this.formState.departmentFixRate ? this.formState.departmentFixRate * 100 : this.formState.departmentFixPercent;
          this.formState.lastFixPercent = this.formState.lastFixRate ? this.formState.lastFixRate * 100 : this.formState.lastFixPercent;
          this.formState.personalRuleContent = JSON.parse(this.formState.personalRuleContent);
          this.formState.departmentRuleContent = JSON.parse(this.formState.departmentRuleContent);
          this.formState.lastRuleContent = JSON.parse(this.formState.lastRuleContent);
          this.formState.personalRuleContent.map(item => {
            item.percent = item.rate ? item.rate * 100 : item.percent
          })
          this.formState.departmentRuleContent.map(item => {
            item.percent = item.rate ? item.rate * 100 : item.percent
          })
          this.formState.lastRuleContent.map(item => {
            item.percent = item.rate ? item.rate * 100 : item.percent
          })
        }
      } catch ({ message }) {
      }
    },

    submit() {
      this.$refs['formRef'].validate()
        .then(() => {
          if (this.formState.personalRuleContent.length < 3) {
            if (this.formState.personalRuleContent[0].max - 1 > this.formState.personalRuleContent[1].min) {
              this.$message.error("阶梯条件的签单数输入错误");
              return
            }
          } else {
            for (let i = 0; i < this.formState.personalRuleContent.length; i++) {
              if (i == 0) {
                if (this.formState.personalRuleContent[i].max > this.formState.personalRuleContent[i + 1].min) {
                  this.$message.error("阶梯条件的签单数输入错误");
                  return
                }
              } else if (i === this.formState.personalRuleContent.length - 1) {
                if (this.formState.personalRuleContent[i - 1].max > this.formState.personalRuleContent[i].min) {
                  this.$message.error("阶梯条件的签单数输入错误");
                  return
                }
              } else {
                if (this.formState.personalRuleContent[i - 1].max > this.formState.personalRuleContent[i].min) {
                  this.$message.error("阶梯条件的签单数输入错误");
                  return
                } else if (this.formState.personalRuleContent[i].max > this.formState.personalRuleContent[i + 1].min) {
                  this.$message.error("阶梯条件的签单数输入错误");
                  return
                }
              }
            }
          }
          if (this.formState.departmentRuleContent.length < 3) {
            if (this.formState.departmentRuleContent[0].max - 1 > this.formState.departmentRuleContent[1].min) {
              this.$message.error("阶梯条件的签单数输入错误");
              return
            }
          } else {
            for (let i = 0; i < this.formState.departmentRuleContent.length; i++) {
              if (i == 0) {
                if (this.formState.departmentRuleContent[i].max > this.formState.departmentRuleContent[i + 1].min) {
                  this.$message.error("阶梯条件的签单数输入错误");
                  return
                }
              } else if (i === this.formState.departmentRuleContent.length - 1) {
                if (this.formState.departmentRuleContent[i - 1].max > this.formState.departmentRuleContent[i].min) {
                  this.$message.error("阶梯条件的签单数输入错误");
                  return
                }
              } else {
                if (this.formState.departmentRuleContent[i - 1].max > this.formState.departmentRuleContent[i].min) {
                  this.$message.error("阶梯条件的签单数输入错误");
                  return
                } else if (this.formState.departmentRuleContent[i].max > this.formState.departmentRuleContent[i + 1].min) {
                  this.$message.error("阶梯条件的签单数输入错误");
                  return
                }
              }
            }
          }
          if (this.formState.lastRuleContent.length < 3) {
            if (this.formState.lastRuleContent[0].max - 1 > this.formState.lastRuleContent[1].min) {
              this.$message.error("阶梯条件的签单数输入错误");
              return
            }
          } else {
            for (let i = 0; i < this.formState.lastRuleContent.length; i++) {
              if (i == 0) {
                if (this.formState.lastRuleContent[i].max > this.formState.lastRuleContent[i + 1].min) {
                  this.$message.error("阶梯条件的签单数输入错误");
                  return
                }
              } else if (i === this.formState.lastRuleContent.length - 1) {
                if (this.formState.lastRuleContent[i - 1].max > this.formState.lastRuleContent[i].min) {
                  this.$message.error("阶梯条件的签单数输入错误");
                  return
                }
              } else {
                if (this.formState.lastRuleContent[i - 1].max > this.formState.lastRuleContent[i].min) {
                  this.$message.error("阶梯条件的签单数输入错误");
                  return
                } else if (this.formState.lastRuleContent[i].max > this.formState.lastRuleContent[i + 1].min) {
                  this.$message.error("阶梯条件的签单数输入错误");
                  return
                }
              }
            }
          };
          this.addOrUpdate();
        })
        .catch(error => {
          console.log('error', error);
        });
    },

    async addOrUpdate() {
      if (this.formState.personalFixPercent == '' || this.formState.personalFixPercent == null) {
        this.formState.personalFixRate = ''
      } else {
        this.formState.personalFixRate = this.formState.personalFixPercent / 100;
      }
      if (this.formState.departmentFixPercent == '' || this.formState.departmentFixPercent == null) {
        this.formState.departmentFixRate = ''
      } else {
        this.formState.departmentFixRate = this.formState.departmentFixPercent / 100;
      }
      if (this.formState.lastFixPercent == '' || this.formState.lastFixPercent == null) {
        this.formState.lastFixRate = ''
      } else {
        this.formState.lastFixRate = this.formState.lastFixPercent / 100;
      }
      this.formState.personalRuleContent.map(item => {
        if (item.percent == '' || item.percent == null) {
          item.rate = ''
        } else {
          item.rate = item.percent / 100;
        }
      })
      this.formState.departmentRuleContent.map(item => {
        if (item.percent == '' || item.percent == null) {
          item.rate = ''
        } else {
          item.rate = item.percent / 100;
        }
      })
      this.formState.lastRuleContent.map(item => {
        if (item.percent == '' || item.percent == null) {
          item.rate = ''
        } else {
          item.rate = item.percent / 100;
        }
      })
      try {
        let res = await this.$http.post('/institutions/commission/rule/update', {
          id: this.selectedRows[0].id,
          planRuleName: this.formState.planRuleName,
          adaptDepartments: this.currDepartments,
          responsibleEmployeeId: this.formState.responsibleEmployeeId,
          responsibleEmployeeName: this.formState.responsibleEmployeeName,
          personalType: this.formState.personalType,
          personalFixRate: this.formState.personalFixRate,
          personalRuleContent: JSON.stringify(this.formState.personalRuleContent),
          departmentType: this.formState.departmentType,
          departmentDouble: this.formState.departmentDouble,
          departmentFixRate: this.formState.departmentFixRate,
          departmentRuleContent: JSON.stringify(this.formState.departmentRuleContent),
          lastType: this.formState.lastType,
          lastFixRate: this.formState.lastFixRate,
          lastRuleContent: JSON.stringify(this.formState.lastRuleContent),
        });
        if (res.code == '200') {
          this.$message.success("编辑成功！");
          this.$emit('close')
        } else {
          this.$message.error(res.message);
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    filterOption(input, option) {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    filterSouOption(input, option) {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    employeesChange(e) {
      this.employees.map(item => {
        if (item.id === e) {
          this.formState.responsibleEmployeeName = item.name
        }
      })
    },

    personalAdd() {
      this.formState.personalRuleContent.splice(this.formState.personalRuleContent.length - 1, 0, {
        min: '',
        max: '',
        rate: '',
      })
    },

    personalDelete(index) {
      this.formState.personalRuleContent.splice(index, 1)
    },

    manageAdd() {
      this.formState.departmentRuleContent.splice(this.formState.departmentRuleContent.length - 1, 0, {
        min: '',
        max: '',
        rate: '',
      })
    },

    manageDelete(index) {
      this.formState.departmentRuleContent.splice(index, 1)
    },

    preManageAdd() {
      this.formState.lastRuleContent.splice(this.formState.lastRuleContent.length - 1, 0, {
        min: '',
        max: '',
        rate: '',
      })
    },

    preManageDelete(index) {
      this.formState.lastRuleContent.splice(index, 1)
    },
  },
};
</script>

<style lang="scss" scoped>
.m-bottom {
  margin-bottom: 10px;
}

.m-bottom-large {
  margin-bottom: 20px;
}

.m-top-alrge {
  margin-top: 20px;
}
</style>